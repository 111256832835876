<template>
  <div>
    <div class="section section__news-page">
      <div class="section__wrapper">

        <div class="row">
          <div class="col-md-8">
            <div class="section__news-page-wrapper">
              <div class="row">
                <div class="col">
                  <div class="title-with-icon mb-5">
                    <div class="title-with-icon__icon title-with-icon__icon_how-learn"></div>
                    <div class="title-with-icon__title">Архив новостей <a href="/news">Новости</a></div>
                  </div>
                </div>
                <div class="col-5 d-none d-lg-block d-xl-none">
                  <div class="mb-8 section__calendar-search-wrapper">
                    <div class="section__calendar-search mb-1">
                      <input type="text" id="search-text" v-model="search" placeholder="Поиск по новостям">
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="false" class="row section__education-filters mb-4">
                <div class="col mb-1">
                  <div class="d-inline-flex section__education-filters-sort">
                    <div class="mr-3">Сортировать по:</div>
                    <a href="#" class="mr-2 active">Популярности</a>
                    <a href="#" class="mr-2">Дате</a>
                    <div class="dropdown">
                      <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                         aria-expanded="false">
                        Тегу новости
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item active" href="#">Актуальные новости</a>
                        <a class="dropdown-item" href="#">Мероприятия</a>
                        <a class="dropdown-item" href="#">Новые курсы</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto mb-1">
                  <a class="section__education-filters-close" href="#">Сбросить фильтр</a>
                </div>
              </div>

              <div class="container-grid">
                <div v-for="post in news" :key="post.id" class="container-grid__item">
                  <div class="section__news-item container-shadow fill-white">
                    <div class="section__news-image" :style="{
                      backgroundImage: post.cover ? 'url(' + post.cover['500x'] + ')' : '',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }"></div>
                    <div class="section__news-content">
                      <div class="section__news-category-wrapper d-flex align-items-center mb-1">
                        <span class="section__news-category">{{ post.rubric }}</span>•<span class="section__news-date">{{ post.date | formatDate('D') }} {{ post.date | formatDate('M') }} {{ post.date | formatDate('Y') }}</span>
                      </div>
                      <div class="section__news-title">
                        <a :href="`/news/${post.id}`">{{ post.title }}</a>
                      </div>
                    </div>
                    <div class="section__news-footer">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="section__news-view">{{ post.views_count }}</div>
                        <a :href="`/news/${post.id}`" class="link-target"><span>Подробнее</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="showLoadMore" class="load-more__wrapper mt-5 mb-5">
                <button @click="loadMore" class="btn btn-primary">
                  <span></span> Загрузить ещё
                </button>
              </div>
            </div>

          </div>
          <div class="col-md-4">
            <div class="mb-4 section__calendar-search-wrapper d-none d-md-block">
              <div class="section__calendar-search mb-1">
                <input type="text" v-model="search" name="" placeholder="Поиск по новостям">
              </div>
            </div>
            <div class="content_title mb-4">Популярные новости</div>
            <div class="section__library-doc-docs">
              <a v-for="post in popularNews" :key="post.id" :href="'/news/' + post.id" class="section__library-doc">
                <span class="section__library-doc-target target-sm"></span>
                <span class="section__library-doc-category">{{ post.rubric }}</span>
                <span class="section__library-doc-title">
                {{ post.title }}
              </span>
                <span class="section__library-doc-info d-flex">
              <span class="section__library-doc-date">{{ post.date | formatDate('D') }} {{ post.date | formatDate('M') }} {{ post.date | formatDate('Y') }}</span>
              <span class="section__library-doc-view">{{ post.views_count }}</span>
              </span>
              </a>
            </div>

            <div class="row">
              <div class="col-md-12 col-6">
                <div class="content_title content_700 mb-24">Подписаться на новости</div>
                <form class="needs-validation" novalidate>
                  <div class="form-item">
                    <input type="email" class="form-control" required name="email" id="letter-email" placeholder="Введите e-mail" />
                    <div class="invalid-feedback">Введите e-mail</div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button type="submit" class="link-target"><span>Подписаться</span></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import PageFooter from "../components/PageFooter";
const LIMIT = 10
export default {
  name: "NewsArchive",
  components: {PageFooter},
  data() {
    return {
      news: null,
      popularNews: null,
      showLoadMore: false,
      offset: 0,
      search: null
    }
  },
  mounted() {
    ApiService.get('posts', {
      params: {
        order_by: 'popular'
      }
    }).then(res => {
      this.popularNews = res.data.posts
    })
    this.$parent.init()
    this.load()
  },
  methods: {
    load(append = false) {
      ApiService.get('posts', {
        params: {
          limit: LIMIT,
          'filters[archive]': 1,
          offset: this.offset
        }
      }).then(res => {
        if(append) {
          this.news = this.news.concat(res.data.posts)
        } else {
          this.news = res.data.posts
        }
        if(!res.data.posts.length || res.data.posts.length < 10) {
          this.showLoadMore = false
        } else {
          this.showLoadMore = true
        }
      })
    },
    loadMore() {
      this.offset += LIMIT
      this.load(true)
    }
  },
  watch: {
    search(val) {
      this.offset = 0
      if(val && val.length > 3) {
        ApiService.get('posts', {
          params: {
            limit: LIMIT,
            'filters[archive]': 1,
            search: val
          }
        }).then(res => {
          this.news = res.data.posts
        })
      } else {
        this.load()
      }
    }
  }
}
</script>

<style scoped>

</style>