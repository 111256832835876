<template>
  <section class="section section__learn" id="section1">
    <div class="section__wrapper">
      <div class="section__learn-grid d-flex flex-wrap">
        <div class="section__learn-container section__learn-description">
          <div class="title-with-icon mb-2">
            <div class="title-with-icon__icon title-with-icon__icon_home"></div>
            <div class="title-with-icon__title">Научиться экспорту</div>
          </div>
          <div class="text">Обучение в Школе экспорта РЭЦ поможет вам получить необходимые знания о всех аспектам экспортной деятельности, нивелировать риски и минимизировать расходы, научиться использовать доступный государственный инструментарий поддержки экспорта. Всё это в Школе экспорта РЭЦ!</div>
          <div class="title-with-icon mb-md-2 mt-3 mb-3">
            <div class="title-with-icon__icon title-with-icon__icon_school"></div>
            <div class="title-with-icon__title">Что такое Школа экспорта?</div>
          </div>
          <div class="text">Школа экспорта РЭЦ – лицензированная образовательная организация, созданная на базе Российского экспортного центра для реализации образовательных и акселерационных программ для российского бизнеса.</div>
        </div>
        <div class="section__learn-container section__learn-statistics">
          <div class="container-shadow">
            <div class="slide-statistics">
              <div class="slide-statistics__arrow-left"></div>
              <div class="slide-statistics__arrow-right"></div>
              <div class="slide-statistics__data">
                <div class="slide-statistics__data-quantity">
                  <span>{{ learn.users | formatNumber }}</span>
                  <span class="data-hidden">{{ learn.themes | formatNumber }}</span>
                </div>
                <div class="slide-statistics__data-text">
                  <span>человек, прошедших обучение</span>
                  <span class="data-hidden">тем экспортных семинаров</span>
                </div>
              </div>
              <div class="slide-statistics__data">
                <div class="slide-statistics__data-quantity">
                  <span>{{ learn.regions | formatNumber }}</span>
                  <span class="data-hidden">{{ learn.accelerators | formatNumber }}</span>
                </div>
                <div class="slide-statistics__data-text">
                  <span>регионов-участников программы экспортных семинаров</span>
                  <span class="data-hidden">акселерационных программ</span>
                </div>
              </div>
              <div class="slide-statistics__data">
                <div class="slide-statistics__data-quantity">
                  <span>{{ learn.reviews | formatNumber }}</span>
                  <span class="data-hidden">{{ learn.courses | formatNumber }}</span>
                </div>
                <div class="slide-statistics__data-text">
                  <span>отзывов слушателей</span>
                  <span class="data-hidden">дистанционных курсов</span>
                </div>
              </div>
              <div class="slide-statistics__data">
                <div class="slide-statistics__data-quantity">
                  <span>{{ learn.seminars | formatNumber }}</span>
                  <span class="data-hidden">{{ learn.tutorials | formatNumber }}</span>
                </div>
                <div class="slide-statistics__data-text">
                  <span>проведено экспортных семинаров</span>
                  <span class="data-hidden">учебных пособий</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section__learn-container section__learn-image">
          <a data-fancybox href="https://exporteduru.servicecdn.ru/video_fragments/34/mp4/5a5896b24e93c_720p.mp4" class="container-shadow" style="background-image: url('pic/what-school-image.jpg'); background-size: cover">
            <div class="arrow-button"></div>
          </a>
        </div>
        <div class="section__learn-container section__learn-feedback">
          <div class="title-with-icon mb-3">
            <div class="title-with-icon__icon title-with-icon__icon_feedback"></div>
            <div class="title-with-icon__title">Отзывы</div>
          </div>
          <div class="container-shadow">
            <div class="section__learn-feedback-text owl-carousel" id="syncFeedback1">
              <div v-for="(review, index) in reviews" class="item" :key="index">
                <div class="feedback-wrapper">
                  <div class="feedback-content">
                    {{ review.text }}
                  </div>
                  <div class="feedback-author"><strong>{{ review.user.fullname }}</strong></div>
                </div>
              </div>
            </div>
            <div class="section__learn-feedback-users">
              <div class="user-placeholder"></div>
              <div class="owl-carousel" id="syncFeedback2">
                <div v-for="(review, index) in reviews" class="item" :key="index">
                  <div class="avatar avatar_sm" :style="{backgroundImage: 'url(' + review.user.avatar['500x'] + ')'}"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
window.jQuery = $
export default {
  name: "LearnExport",
  props: ['reviews', 'learn'],
  mounted() {
    require('@fancyapps/fancybox')
    $('[data-fancybox]').fancybox({
      video: {
        tpl:
            '<video class="fancybox-video" controls controlsList="nodownload" poster="{{poster}}">' +
            '<source src="{{src}}" type="{{format}}" />' +
            "</video>",
        format: "", // custom video format
        autoStart: true
      },
    });
  }
}
</script>

<style scoped>
.avatar_sm {
  background-size: contain;
}
.container-shadow {
  display: block;
}
</style>