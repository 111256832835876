<template>
  <div>
    <div class="section section__education">
      <template v-if="program && courses">
        <div class="section__wrapper fadeIn">
          <ul class="nav nav-tabs mb-5" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="online-tab" data-toggle="tab" href="#online" role="tab" aria-controls="online"
                 aria-selected="true">Онлайн-программа</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="export-tab" data-toggle="tab" href="#export" role="tab" aria-controls="export"
                 aria-selected="false">Экспортные семинары</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="corporate-tab" data-toggle="tab" href="#corporate" role="tab" aria-controls="corporate"
                 aria-selected="false">Корпоративное обучение</a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="online" role="tabpanel" aria-labelledby="online-tab">
              <div class="row align-items-stretch mb-5">
                <div class="col section__education-description">
                  <div class="title-with-icon mb-4">
                    <div class="title-with-icon__icon title-with-icon__icon_programms-school"></div>
                    <div class="title-with-icon__title">Обучение: онлайн-программа</div>
                  </div>
                  <div class="section__education-description-text">
                    <p>Каждый из дистанционных курсов Школы экспорта РЭЦ посвящен отдельному этапу экспортного проекта. Вы самостоятельно определяете, какие курсы и в каком порядке проходить.</p>
                    <p>Для начала зарегистрируйтесь на нашем сайте, выберете интересующий курс и подайте заявку на дистанционную форму обучения.</p>
                    <p>После одобрения заявки вы получите годовой доступ ко всем дистанционным курсам Школы экспорта РЭЦ.</p>
                  </div>
                  <div><a href="/online" class="link-target mt-3"><span>записаться на онлайн-программу</span></a></div>
                </div>
                <div v-if="newCourse" class="col-md-4 mb-3 mb-md-0 section__education-tag">
                  <div class="container-shadow">
                    <div class="section__education-tag-name">Новый курс</div>
                    <div class="section__education-tag-title"><a :href="`/education/${program.alias}/${newCourse.alias}`">
                      {{ newCourse.title }}
                    </a>
                    </div>
                    <div class="section__education-tag-content" v-html="newCourse.short_desc"></div>
                    <div><a :href="`/education/${program.alias}/${newCourse.alias}`" class="link-target"><span>подробнее</span></a></div>
                  </div>
                </div>
              </div>

              <div class="row section__education-filters mb-3">
                <div class="col mb-1">
                  <div class="d-inline-flex section__education-filters-sort">
                    <div class="mr-3">Сортировать по:</div>
                    <a href="javascript:void(0)" @click="orderByEvents('rank')" :class="{'active' : orderBy === 'rank'}" class="mr-2">Популярности</a>
                    <a href="javascript:void(0)" @click="orderByEvents('date')" :class="{'active': orderBy === 'date'}">Дате</a>
                  </div>
                </div>
                <div class="col-auto mb-1">
                  <span @click="clearFilters" class="section__education-filters-close">Сбросить фильтр</span>
                </div>
              </div>
              <template v-if="courses">
                <div v-for="(course,key) in courses" :key="key" :class="{loading: loading}" class="program-card container-shadow">
                  <div class="program-card__grid">
                    <div class="program-card__grid-img">
                      <img v-if="course.preview" :src="course.preview['500x']"/>
                      <img v-else src="/pic/course-img.jpeg"/>
                    </div>
                    <div class="program-card__grid-data">
                      <div class="program-card__grid-date">{{ course.start_date_text }}</div>
                      <div class="program-card__grid-title">
                        <a :href="`/education/${program.alias}/${course.alias}`">
                          {{ course.title }}
                        </a>
                      </div>
                      <div class="program-card__grid-content">
                        {{ course.short_desc }}
                      </div>
                      <div class="program-card__grid-links d-flex align-items-center justify-content-between">
                        <div class="mt-1"><a class="btn btn-outline-primary" :href="`/education/${program.alias}/${course.alias}`">Подробнее</a></div>
                        <div class="mt-1"><a href="/online" class="link-target ml-0"><span>записаться на курс</span></a></div>
                      </div>
                    </div>
                    <div class="program-card__grid-info">
                      <div class="program-card__grid-info-item program-card__grid-info_rate">
                        <div class="program-card__grid-info-label"><a :href="`/education/${program.alias}/${course.alias}#discuss`">{{ course.review_count }}</a></div>
                        <div class="program-card__grid-info-content">
                          <div class="rating">
                            <span class="filled"></span>
                            <span class="filled"></span>
                            <span class="filled"></span>
                            <span class="filled"></span>
                            <span class="semi-filled"></span>
                          </div>
                        </div>
                      </div>
                      <div class="program-card__grid-info-item">
                        <div class="program-card__grid-info-label">Стоимость:</div>
                        <div class="program-card__grid-info-content">на бесплатной основе</div>
                      </div>
                      <div class="program-card__grid-info-item">
                        <template v-if="course.tutors.length">
                          <div class="program-card__grid-info-label">Авторы:</div>
                          <div class="program-card__grid-info-content">
                            <template v-if="!showMore[course.id]">
                            <span v-for="(tutor, index) in course.tutors.slice(0, 4)" :key="index">
                                {{ tutor.fullname }}<br/>
                            </span>
                            </template>
                            <template v-else>
                            <span v-for="(tutor, index) in course.tutors" :key="index">
                                {{ tutor.fullname }}<br/>
                            </span>
                            </template>
                            <div class="show-more" v-if="course.tutors.length > 4">
                            <span class="href" @click="showMoreTutor(course.id)">
                              {{ showMore[course.id] ? 'Скрыть' : 'Показать ещё' }}
                            </span>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div v-if="false" class="load-more__wrapper mt-5">
                <button class="btn btn-primary" @click="loadMore()">
                  <span></span> Загрузить ещё
                </button>
              </div>
            </div>
            <div class="tab-pane fade" id="export" role="tabpanel" aria-labelledby="export-tab">
              <seminars />
            </div>
            <div class="tab-pane fade" id="reu" role="tabpanel" aria-labelledby="export-tab">
              <seminars />
            </div>
            <div class="tab-pane fade" id="corporate" role="tabpanel" aria-labelledby="corporate-tab">
              <corporates/>
            </div>
          </div>
        </div>
      </template>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from "../components/PageFooter";
import ApiService from "../../services/api.service";
import Seminars from "../components/Seminars";
import Corporates from "../components/Corporates";
const DEFAULT_PROGRAM = 3
export default {
  name: "EducationPage",
  components: {Corporates, Seminars, PageFooter},
  data() {
    return {
      courses: null,
      offset: 10,
      showMore: {},
      newCourse: null,
      direction: 'asc',
      orderBy: null,
      program: null,
      loading: false
    }
  },
  mounted() {
    this.getProgram()
    ApiService.get('courses', {
      params: {
        limit: 15,
        program: DEFAULT_PROGRAM,
        order_by: this.orderBy,
        order_direction: this.direction
      }
    }).then(res => {
      this.courses = res.data.courses
      this.newCourse = res.data.newCourse
      this.$parent.init()
    })
  },
  methods: {
    orderByEvents(orderBy = 'rank') {
      this.direction = this.direction === 'asc' ? 'desc' : 'asc'
      this.orderBy = orderBy
      this.getEvents()
    },
    clearFilters() {
      this.direction = null
      this.orderBy = null
      this.getEvents()
    },
    getProgram() {
      ApiService.get(`programs/${DEFAULT_PROGRAM}`).then(res => {
        this.program = res.data
      })
    },
     getEvents() {
      this.loading = true
      ApiService.get('courses', {
        params: {
          limit: 15,
          program: DEFAULT_PROGRAM,
          order_by: this.orderBy,
          order_direction: this.direction
        }
      }).then(res => {
        this.loading = false
        this.courses = res.data.courses
        this.newCourse = res.data.newCourse
      })
    },
    loadMore() {
      this.offset += 10;
      ApiService.get('courses', {
        params: {
          limit: 10,
          offset: this.offset,
          program: DEFAULT_PROGRAM
        }
      }).then(res => {
        this.courses = this.courses.concat(res.data.courses)
      })
    },
    showMoreTutor(id) {
      this.showMore[id] = !this.showMore[id]
      this.getEvents()
    }
  }
}
</script>

<style lang="scss">
.href {
  font-weight: 600;
  color: #002E5E;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.section__education-tag-content {
  display: -webkit-box;
  max-height: 140px;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>