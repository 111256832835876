<template>
  <ul class="navigation" id="menu">
    <li data-menuanchor="front"><a href="#front"><span>01</span></a></li>
    <li data-menuanchor="learn"><a href="#learn"><span>02</span></a></li>
    <li data-menuanchor="school"><a href="#school"><span>03</span></a></li>
    <li data-menuanchor="news"><a href="#news"><span>04</span></a></li>
    <li v-if="false" data-menuanchor="coaches"><a href="#coaches"><span>05</span></a></li>
    <li data-menuanchor="footer"><a href="#footer"><span>05</span></a></li>
  </ul>
</template>

<script>
export default {
  name: "Navigation"
}
</script>

<style scoped>

</style>