<template>
  <div class="comment container-shadow">
    <div class="comment__image">
      <div class="avatar avatar_big container-shadow" :style="{backgroundImage: 'url(' + (review.user.avatar ? review.user.avatar['500x'] : '/pic/user-pic.jpeg') + ')'}"></div>
    </div>
    <div class="comment__content">
      <div class="comment__header d-flex justify-content-between">
        <div class="comment__name">{{ review.user.fullname }}</div>
        <div class="d-inline-flex">
          <div class="rating mr-2">
            <span v-if="review.rate > 0" class="filled"></span>
            <span v-else></span>
            <span v-if="review.rate > 1" class="filled"></span>
            <span v-else></span>
            <span v-if="review.rate > 2" class="filled"></span>
            <span v-else></span>
            <span v-if="review.rate > 3" class="filled"></span>
            <span v-else></span>
            <span v-if="review.rate > 4" class="filled"></span>
            <span v-else></span>
          </div>
          <div class="rating__data">{{ review.rate }}/5</div>
        </div>
      </div>
      <div class="comment__date">{{ review.date | formatDate('DD.MM.YYYY') }}</div>
      <div class="comment__text">
        {{ review.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentItem",
  props: ['review']
}
</script>

<style scoped>
</style>