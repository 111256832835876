<template>
  <div>
    <calendar/>
    <page-footer/>
  </div>
</template>

<script>
import Calendar from "../components/Calendar";
import PageFooter from "../components/PageFooter";
export default {
  name: "CalendarPage",
  components: {PageFooter, Calendar}
}
</script>

<style scoped>

</style>