<template>
  <div id="fullpage" v-if="loading">
    <front :events="events" />
    <learn-export :reviews="reviews" :learn="learn" />
    <school-programs :school="school" />
    <news :posts="posts"/>
    <coachers v-if="false" :tutors="tutors"/>
    <page-footer/>
    <navigation/>
  </div>
</template>

<script>
import Front from "../components/Front";
import LearnExport from "../components/LearnExport";
import SchoolPrograms from "../components/SchoolPrograms";
import News from "../components/News";
import Coachers from "../components/Coachers";
import PageFooter from "../components/PageFooter";
import ApiService from "../../services/api.service";
//import _ from 'lodash';
import Navigation from "../components/Navigation";
export default {
  name: "Home",
  components: {
    Navigation,
    PageFooter,
    Coachers,
    News,
    SchoolPrograms,
    LearnExport,
    Front
  },
  data() {
    return {
      loading: false,
      events: null,
      reviews: null,
      posts: null,
      tutors: null,
      learn: null,
      school: null
    }
  },
  created() {
    ApiService.get('staticpage/home').then(res => {
      this.loading = true
      this.events = res.data.home.events
      //this.events = _.chunk(res.data.home.events, 2)
      this.reviews = res.data.home.reviews
      this.posts = res.data.home.posts
      this.tutors = res.data.home.tutors
      this.school = res.data.school
      this.learn = res.data.learn
      this.$parent.init()
    })
  }
}
</script>
<style lang="scss">
@media (max-width: 768px) {
  .feedback-content {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 14rem!important;
  }
  .section__school-container-links {
    margin-top: 0!important;
  }
}
</style>