<template>
  <div class="section section__calendar">
    <div class="section__wrapper">
      <div class="row section__calendar-header mb-4">
        <div class="col-sm-7">
          <div class="section__calendar-content">
            <div class="title-with-icon mb-2">
              <div class="title-with-icon__icon title-with-icon__icon_calendar-2"></div>
              <div class="title-with-icon__title">Календарь обучения</div>
            </div>

            <div class="section__calendar-content">
              <div class="section__calendar-description">
                В календаре обучения вы можете найти расписание ближайших семинаров в вашем городе и записаться на них.
                Интересующего вас семинара нет в списке? Уточнить проведение экспортных семинаров можно у <a href="#">регионального
                оператора</a>.
              </div>
            </div>
          </div>

        </div>
        <div class="col-sm-5">
          <div class="btn-socials mb-3">
            <a class="container-shadow btn-social btn-social__vk" href="http://vk.com/exportedu"></a>
          </div>

          <div class="section__calendar-search mb-3">
            <input type="text" v-model="query" placeholder="Поиск по семинарам"/>
            <div class="section__calendar-search-actions">
              <div class="close"></div>
              <button class="btn btn-primary btn-sm"><span></span> Искать</button>
            </div>
          </div>
        </div>
      </div>

      <h2 class="mb-3 section__calendar-month d-none d-md-block">
        Экспортные семинары — <span id="calendar-month"></span>
      </h2>

      <div class="row section__calendar-row">
        <div class="col-md-8">
          <div class="section__calendar-content">
            <h2 class="mb-3 section__calendar-month d-block d-md-none">
              Экспортные семинары — <span id="calendar-month-mobile"></span>
            </h2>
            <template v-if="events && events.length">
              <div v-for="(event, index) in events" :key="index" class="seminar-item container-shadow">
                <div class="seminar-item__body">
                  <div class="seminar-item__date" v-if="event.date">
                    <div class="seminar-item__day">{{ event.date.start | formatDate('D') }}</div>
                    <div class="seminar-item__month">{{ event.date.start | formatDate('M') }} {{ event.date.start | formatDate('Y') }}</div>
                    <div class="seminar-item__time">{{ event.date.start | formatDate('HH:mm') }}</div>
                  </div>
                  <div class="seminar-item__image">
                    <img :src="event.cover ? event.cover['500x'] : '/images/event.jpeg'" title="" alt="" />
                  </div>
                  <div class="seminar-item__description">
                    <div class="d-flex justify-content-between mb-3">
                      <div class="seminar-item__type">{{ event.api_type }}</div>
                      <div class="seminar-item__viewers">{{ event.count_participants }}</div>
                    </div>
                    <div class="seminar-item__title"><h2><a :href="'/events/' + event.id">{{ event.title }}</a></h2></div>
                    <div class="seminar-item__coach">
                      <span class="d-block">Тренер</span>
                      <div>{{ event.tutors && event.tutors.length ? event.tutors[0].fullname : '' }}</div>
                    </div>
                  </div>
                </div>
                <div class="seminar-item__footer d-flex justify-content-between">
                  <div class="seminar-item__location mr-3">
                    {{ event.location }}
                  </div>
                  <router-link :to="`/events/${event.id}`" class="link-target"><span>записаться на семинар</span></router-link>
                </div>
              </div>
            </template>
            <template v-if="events && !events.length">
              <h3>Семинаров по Вашему запросу не найдено</h3>
            </template>
            <div class="load-more__wrapper" v-if="showLoadMore">
              <button class="btn btn-primary" @click="loadMore()">
                <span></span> Загрузить ещё
              </button>
            </div>

            <div v-if="courses" class="d-block d-md-none section__calendar-courses-wrapper">
              <h2 class="mb-4">Курсы ({{ courses.length }})</h2>

              <div v-for="(course, key) in courses" :key="key" class="section__calendar-courses">
                <a :href="`/education/jiznennyy-cikl-eksportnogo-proekta/${course.alias}#seminars`" class="section__calendar-course">
                  <span class="section__calendar-course-target target-sm"></span>
                  <span class="section__calendar-course-title">{{ course.seminar_name }}</span>
                  <span class="section__calendar-course-views">{{ course.events_count }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="container-shadow section__calendar-calendar mb-4">
            <div id="calendar-container" class="section__calendar-table"></div>

            <div class="section__calendar-filter">
              <h2 class="mb-1">Фильтровать по региону</h2>
              <select @change="changeRegion()" v-model="region" id="fff" name="" data-placeholder="Выберите регион">
                <option :value="null">Все регионы</option>
                <option v-for="(region, key) in regions" :key="key" :value="region.id">
                  {{region.region_name_ru}}
                </option>
              </select>
              <div class="my-4">
                <div class="checkbox-wrapper">
                  <template v-if="false">
                    <div class="checkbox">
                        <input type="checkbox" name="" value="" id="asdf" />
                        <label for="asdf">Показывать только мои события</label>
                    </div>
                  </template>
                </div>
              </div>
              <a href="javascript:void(0)" @click="clearFilters" class="btn btn-outline-primary">Все семинары</a>
            </div>
          </div>
          <div class="d-none d-md-block">
            <h2 class="mb-4">Курсы (11)</h2>
            <div v-for="(course, key) in courses" :key="key" class="section__calendar-courses">
              <a :href="'/education/start-eksportnoy-deyatelnosti-10-shagov-k-uspehu/' + course.alias + '#seminars'" class="section__calendar-course">
                <span class="section__calendar-course-target target-sm"></span>
                <span class="section__calendar-course-title">{{ course.seminar_name }}</span>
                <span class="section__calendar-course-views">{{ course.events_count }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
export default {
  name: "Calendar",
  data() {
    return {
      events: null,
      offset: 0,
      regions: null,
      source: null,
      courses: null,
      showLoadMore: true,
      region: null,
      date: null,
      query: ''
    }
  },
  mounted() {
    let jquery = document.createElement("script");
    jquery.setAttribute("src",'/js/jquery.min.js');
    document.head.appendChild(jquery);

    let scripts = [
      '/js/bootstrap-datepicker.js',
      '/js/pickmeup.min.js',
      '/js/scrolloverflow.js',
      '/js/owl.carousel.js',
      '/js/chosen.jquery.min.js',
      '/js/jquery.mask.min.js',
      '/js/jquery.fullPage.js'
    ];
    scripts.forEach((script, key) => {
      setTimeout(() => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
        if(key === 1) {
          tag.onload = () => {
            this.getEvents()
            this.getStatic()
            let self = this
            setTimeout(function () {
              document.getElementById('calendar-container').addEventListener('pickmeup-change',  (e) => {
                self.date = e.detail.formatted_date
                self.changeDate()
              })
            }, 500)
          }
        }
      }, 100)
    });

  },
  methods: {
    clearFilters() {
      window.location.reload()
    },
    loadMore() {
      this.offset += 10;
      this.getEvents()
    },
    changeRegion() {
      this.offset = 0
      this.events = null
      this.getEvents()
    },
    changeDate() {
      this.offset = 0
      this.events = null
      this.getEvents()
    },
    getEvents() {
      const params = new URLSearchParams();
      params.append('filters[type]', 'future');
      params.append('limit', 10);
      params.append('offset', this.offset);
      if(this.region) {
        params.append('filters[region]', this.region);
      }
      if(this.date) {
        params.append('filters[date]', this.date);
      }

      if(this.$route.query.course) {
        params.append('filters[course]', this.$route.query.course);
      }

      ApiService.get('events', {
        params: params
      }).then(res => {
        if(!this.events) {
          this.events = []
        }
        this.events = this.events.concat(res.data.events);
        if(!res.data.events.length || res.data.events.length < 10) {
          this.showLoadMore = false
        } else {
          this.showLoadMore = true
        }
      })
    },
    getStatic() {
      ApiService.get('staticpages/events').then(res => {
        this.regions = res.data.regions;
        this.courses = res.data.courses;
        this.source = res.data.source;
        localStorage.setItem('events', JSON.stringify(this.source))
        let calendarTag = document.createElement("script");
        calendarTag.setAttribute("src", '/js/calendar.js');
        document.head.appendChild(calendarTag);
        let mainTag = document.createElement("script");
        mainTag.setAttribute("src", '/js/my.js');
        document.head.appendChild(mainTag);
      })
    }
  },
  watch: {
    query(val) {
      this.offset = 0
      this.events = []
      if(val.length > 1) {
        ApiService.get('events', {
          params: {
            'filters[type]': 'future',
            limit: 10,
            offset: this.offset,
            search: val
          }
        }).then(res => {
          this.events = res.data.events
          if(!res.data.events.length || res.data.events.length < 10) {
            this.showLoadMore = false
          } else {
            this.showLoadMore = true
          }
        })
      } else if (val.length === 0) {
        this.getEvents()
      }
    }
  }
}
</script>

<style lang="scss">
  .section__calendar-search-actions {
    display: none;
  }
  .pmu-evented {
    background: #1C75BC!important;
    color: #ffffff!important;
    &.pmu-not-in-month {
      background: #E0E0E0!important;
      color: #BDBDBD!important;
    }
    &.pmu-selected {
      background: #002E5E!important;
      color: #fff!important;
    }
  }
  .pickmeup .pmu-instance .pmu-button.pmu-evented:not(.pmu-not-in-month):hover {
    background: #002E5E!important;
    color: #fff!important;
  }
</style>