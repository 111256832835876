<template>
  <div class="section section__article" v-if="post">
    <div class="section__wrapper">
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col">
              <div class="title-with-icon title-with-icon_menu mb-5">
                <div class="title-with-icon__icon title-with-icon__icon_back"></div>
                <div class="title-with-icon__title"><a href="/news">Вернутся к списку новостей</a></div>
              </div>
            </div>
            <div v-if="false" class="col-5 d-none d-lg-block d-xl-none">
              <div class="mb-8 section__calendar-search-wrapper">
                <div class="section__calendar-search mb-1">
                  <input type="text" id="search-text" name="" placeholder="Поиск по новостям">
                  <div class="section__calendar-search-actions">
                    <div class="close"></div>
                    <button class="btn btn-primary btn-sm"><span></span> Искать</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section__article-content">
            <div class="content_title content_700 mb-24">
              {{ post.title }}
            </div>
            <div class="section__library-doc-info d-flex mb-5">
              <span class="section__library-doc-tag"><a href="">{{ post.rubric }}</a></span>
              <span class="section__library-doc-date">{{ post.date | formatDate('D') }} {{ post.date | formatDate('M') }} {{ post.date | formatDate('Y') }}</span>
              <span class="section__library-doc-view">{{ post.views_count }}</span>
            </div>
            <div class="news-short-text" v-html="post.short_text"></div>
            <div class="content_18 mb-5" v-html="post.text"></div>
            <div class="d-flex justify-content-end align-items-center">
              <div class="content_18 mr-2">Поделиться: </div>
              <div class="btn-socials">
                <share-network
                    network="vk"
                    :url="`https://exportedu.ru/news/${post.id}`"
                    :title="post.title"
                    :description="post.short_text"
                    class="container-shadow btn-social btn-social__vk"
                >
                </share-network>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-4">
<!--          <div class="mb-4 section__calendar-search-wrapper d-none d-md-block">
            <div class="section__calendar-search mb-1">
              <input type="text" v-model="search" placeholder="Поиск по новостям">
            </div>
          </div>-->
          <div class="content_title mb-4">Популярные новости</div>
          <div class="section__library-doc-docs">
            <a v-for="post in popularNews" :key="post.id" :href="'/news/' + post.id" class="section__library-doc">
              <span class="section__library-doc-target target-sm"></span>
              <span class="section__library-doc-category">{{ post.rubric }}</span>
              <span class="section__library-doc-title">
                {{ post.title }}
              </span>
              <span class="section__library-doc-info d-flex">
              <span class="section__library-doc-date">{{ post.date | formatDate('D') }} {{ post.date | formatDate('M') }} {{ post.date | formatDate('Y') }}</span>
              <span class="section__library-doc-view">{{ post.views_count }}</span>
              </span>
            </a>
          </div>

          <div class="row">
            <div class="col-md-12 col-6">
              <div class="content_title content_700 mb-24">Подписаться на новости</div>
              <form class="needs-validation" novalidate>
                <div class="form-item">
                  <input type="email" class="form-control" required name="email" id="letter-email" placeholder="Введите e-mail" />
                  <div class="invalid-feedback">Введите e-mail</div>
                </div>
                <div class="d-flex justify-content-end">
                  <button type="submit" class="link-target"><span>Подписаться</span></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
export default {
  name: "NewsPage",
  data() {
    return {
      post: null,
      popularNews: null
    }
  },
  mounted() {
    this.$parent.init()
    ApiService.get(`posts/${this.$route.params.id}`).then(res => {
      this.post = res.data
      ApiService.post(`posts/${this.$route.params.id}/views`).then(() => {
        ++this.post.view_count;
      })
    })
    ApiService.get('posts', {
      params: {
        order_by: 'popular'
      }
    }).then(res => {
      this.popularNews = res.data.posts
    })
  }
}
</script>

<style lang="scss">
.section__article {
  p {
    margin-bottom: 2rem;
  }
  img {
    display: block;
    width: 100%;
    margin: 5rem 0 !important;
    vertical-align: middle;
    border-style: none;
  }
  @media (max-width: 768px) {
    blockquote {
      margin-bottom: 24px;
      padding: 10px 24px;
      font-size: 16px;
      line-height: 1.5;
    }
    .section__library-doc-title {
      font-size: 18px!important;
    }
    .section__library-doc-view {
      font-size: 16px;
    }
    .content_title {
      margin-top: 40px;
    }
  }
}
.news-short-text {
  margin-bottom: 15px;
  font-style: italic;
  font-size: 2.4rem;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}
</style>