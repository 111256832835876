<template>
  <div>
    <div class="section section__about">
      <div class="section__wrapper">
        <div class="row mb-1">
          <div class="col">
            <div class="title-with-icon mb-4">
              <div class="title-with-icon__icon title-with-icon__icon_about-school"></div>
              <div class="title-with-icon__title">О школе экспорта</div>
            </div>
          </div>
          <div class="col-auto">
            <div class="btn-socials mb-4">
              <a class="container-shadow btn-social btn-social__vk" href="http://vk.com/exportedu"></a>
            </div>
          </div>
        </div>

        <ul class="nav nav-tabs mb-5" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all"
               aria-selected="true">Общая информация</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="courses-tab" data-toggle="tab" href="#courses" role="tab" aria-controls="courses"
               aria-selected="false">Наши курсы</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="regions-tab" data-toggle="tab" href="#regions" role="tab" aria-controls="regions"
               aria-selected="false">Операторы программ</a>
          </li>
        </ul>

        <div class="row">
          <div class="col-md-8">
            <div class="section__about-content">
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                  <div class="section__about-all" v-if="page">
                    <span v-html="page"></span>
                  </div>
                </div>
                <div class="tab-pane fade" id="courses" role="tabpanel" aria-labelledby="courses-tab">
                  <div class="section__about-courses">
                    <div v-for="(course, index) in courses" :key="index" class="section__about-course container-shadow">
                      <div class="section__about-course-img">
                        <img v-if="course.preview" :src="course.preview['500x']"/>
                        <img v-else src="/pic/course-img.jpeg"/>
                      </div>
                      <div class="section__about-course-content">
                        <h2 class="section__about-course-title">
                          <a href="#">
                            {{ course.title }}
                          </a>
                        </h2>
                        <div class="section__about-course-text">
                          {{ course.short_desc }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="regions" role="tabpanel" aria-labelledby="regions-tab">
                  <div class="section__about-regions">
                    <ul class="nav nav-tabs nav-tabs_bordered mb-5" id="myTab2" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="basic-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic"
                           aria-selected="true">Базовая<br /> программа семинаров <span></span></a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="acceleration-tab" data-toggle="tab" href="#acceleration" role="tab" aria-controls="acceleration"
                           aria-selected="false">Акселерационная<br /> программа «Экспортный форсаж»  <span></span></a>
                      </li>
                    </ul>

                    <div class="tab-content" id="myTabContent2">
                      <div class="tab-pane fade show active" id="basic" role="tabpanel" aria-labelledby="basic-tab">
                        <div v-if="false" class="row align-items-center">
                          <div class="col-auto text-center">
                            <div class="ext-wrapper">
                              <img src="/pic/icon-doc.svg" />
                            </div>

                          </div>
                          <div class="col">
                            <h2>Реестр тренерского состава программы экспортных семинаров</h2>
                          </div>
                          <div class="col-auto">
                            <a download="Реестр тренерского состава программы экспортных семинаров.pdf" href="//exporteduru.servicecdn.ru/media/default/0003/17/b2b3189111248fb6b74e561ba73061a6e06ce1f0.pdf" target="_blank" class="btn btn-outline-primary btn-download">
                              <span></span> Скачать
                            </a>
                          </div>
                        </div>
                        <div class="table-wrapper">
                          <table>
                            <thead>
                            <tr>
                              <th>Регион</th>
                              <th>Оператор</th>
                              <th>Ссылка</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(contact, index) in contacts" :key="index">
                              <td>{{ contact.region }}</td>
                              <td>{{ contact.operator }}</td>
                              <td>
                                <a class="link-target_sm" :href="contact.operator_site">Перейти</a>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>

                        <div v-if="showLoadMore" class="load-more__wrapper mt-5">
                          <button @click="loadMore()" class="btn btn-primary">
                            <span></span> Загрузить ещё
                          </button>
                        </div>
                      </div>

                      <div class="tab-pane fade" id="acceleration" role="tabpanel" aria-labelledby="acceleration-tab">
                        <div class="row align-items-center">
                          <div class="col">
                            <h2 class="mb-0">Перечень аккредитованных операторов программы</h2>
                          </div>
                        </div>
                        <div class="table-wrapper mt-2 mb-2">
                          <table>
                            <thead>
                            <tr>
                              <th>Регион</th>
                              <th>Оператор</th>
                              <th>Ссылка</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(contact, index) in forsages" :key="index">
                              <td>{{ contact.region }}</td>
                              <td>{{ contact.operator }}</td>
                              <td>
                                <a target="_blank" class="link-target_sm" :href="contact.operator_site">Перейти</a>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row align-items-center mt-5">
                          <div class="col">
                            <h2 class="mb-0">Перечень аккредитованных подрядчиков</h2>
                          </div>
                        </div>
                        <div class="table-wrapper mt-2">
                          <table>
                            <thead>
                            <tr>
                              <th>Регион</th>
                              <th>Подрядчик</th>
                              <th>Контактные данные</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(contact, index) in contractors" :key="index">
                              <td>{{ contact.region }}</td>
                              <td>{{ contact.operator }}</td>
                              <td>
                                <a class="link-target_sm" target="_blank" :href="contact.operator_site">Перейти</a>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <h2 class="mb-2">Актуальные новости</h2>
            <div class="section__about-carousel-wrapper">
              <div class="section__about-carousel owl-carousel">
                <div v-for="post in posts" :key="post.id" class="item">
                  <div class="section__news-item">
                    <div class="section__news-image" :style="{
                      backgroundImage: post.cover ? 'url(' + post.cover['500x'] + ')' : '',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }"></div>
                    <div class="section__news-content">
                      <div class="section__news-title">
                        <a :href="'/news/' + post.id">{{ post.title }}</a>
                      </div>
                      <div class="section__news-text">
                        {{ post.short_text }}
                      </div>
                    </div>
                    <div class="section__news-footer">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="section__news-view">{{ post.views_count }}</div>
                        <a :href="'/news/' + post.id" class="link-target"><span>Подробнее</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import PageFooter from "../components/PageFooter";
const LIMIT = 15
export default {
  name: "About",
  components: {PageFooter},
  data() {
    return {
      page: null,
      courses: null,
      contacts: null,
      forsages: [
        {
          region: 'Алтайский край',
          operator: ' НО "Алтайский фонд развития малого и среднего предпринимательства"',
          operator_site: 'http://www.altfond.ru/'
        },
        {
          region: 'Волгоградская область',
          operator: 'АНО "Центр поддержки экспорта Волгоградской области"',
          operator_site: 'http://volgogradexport.com/'
        },
        {
          region: 'Воронежская область',
          operator: ' «Центр координации поддержки экспортно ориентированных субъектов малого и среднего предпринимательства Воронежской области»',
          operator_site: 'http://export36.ru/'
        },
        {
          region: 'Калужская область',
          operator: 'Государственное автономное учреждение Калужской области "Агенство развития бизнеса"',
          operator_site: 'https://arbko.ru/'
        },
        {
          region: 'Кемеровская область',
          operator: 'АНО "Центр поддержки экспорта Кемеровской области"',
          operator_site: 'https://export42.ru/'
        },
        {
          region: 'Краснодарский край',
          operator: 'Фонд "Центр координации поддержки экспортно-ориентированных субъектов малого и среднего предпринимательства" Краснодарского края',
          operator_site: 'http://www.kubanexport.ru/'
        },
        {
          region: 'Красноярский край',
          operator: 'Акционерное общество "Агентство развития бизнеса и микрокредитная компания"',
          operator_site: 'http://agpb24.ru/'
        },
        {
          region: 'Ленинградская область',
          operator: 'АНО "Центр развития промышленности Ленинградской области"',
          operator_site: 'https://crplo.ru/'
        },
        {
          region: 'Липецкая область',
          operator: 'АНО "Центр координации поддержки экспортно ориентированных субъектов малого и среднего предпринимательства Липецкой области"',
          operator_site: 'http://export48.ru/'
        },
        {
          region: 'Пензенская область',
          operator: '"Фонд поддержки предпринимательства Пензенской области"',
          operator_site: 'https://mbpenza.ru/'
        },
        {
          region: 'Пермский край',
          operator: 'Фонд "Региональный центр инжиниринга"',
          operator_site: 'https://rce-perm.ru/'
        },
        {
          region: 'Республика Башкортостан',
          operator: 'АНО "Центр координации поддержки экспортно ориентированных субъектов малого и среднего предпринимательства Республики Башкортостан"',
          operator_site: 'http://bashexport.com/'
        },
        {
          region: 'Республика Бурятия',
          operator: 'Гарантийный фонд содействия кредитованию субъектов малого и среднего предпринимательства и развития промышленности Республики Бурятия',
          operator_site: 'http://invest-buryatia.ru/'
        },
        {
          region: 'Республика Карелия',
          operator: 'АО "Корпорация развития Республики Карелия"',
          operator_site: 'https://kr-rk.ru/'
        },
        {
          region: 'Республика Марий Эл',
          operator: 'АНО "Агенство инвестиционного и экспортного развития Республики Марий Эл"',
          operator_site: 'ttp://mari-el.gov.ru/mecon/Pages/jurisdiction.aspx'
        },
        {
          region: 'Ростовская область',
          operator: 'АНО "Центр координации поддержки экспортоориентированных субъектов малого и среднего предпринимательства Ростовской области"',
          operator_site: 'http://www.export161.ru/'
        },
        {
          region: 'Саратовская область',
          operator: 'АНО "Центр поддержки экспорта Саратовской области"',
          operator_site: 'https://export64.ru/'
        },
        {
          region: 'Смоленская област',
          operator: 'АНО "Центр поддержки экспорта Смоленской области',
          operator_site: 'https://export67.com/'
        }
      ],
      contractors: [
        {
          region: "Калининградская область",
          operator: "ИП Храповицкая Елена Александровна",
          operator_site: "https://elegantevent.ru/"
        },
        {
          region: "Калужская область",
          operator: "ИП Горбачев Максим Николаевич",
          operator_site: "https://gorstka.ru/"
        },
        {
          region: "Краснодарский край",
          operator: "ООО «Русский экспортный дом»",
          operator_site: "https://export-russia.ru/"
        },
        {
          region: "Новосибирская область",
          operator: "ООО «Инновационная Бизнес Группа»",
          operator_site: "http://www.inbg.ru/"
        },
        {
          region: "Оренбургская область",
          operator: "ООО «ПРОФИ»",
          operator_site: "https://prosocialsolution.ru/"
        },
        {
          region: "Республика Башкортостан",
          operator: "ООО «Информационные решения»",
          operator_site: "ИНН 0277116100 ОГРН 1110280025437"
        },
        {
          region: "Самарская область",
          operator: "ООО «ГЕНРИ»",
          operator_site: "https://www.henrymr.com/"
        },
        {
          region: "Тульская область",
          operator: "ИП Кислова Ирина Петровна",
          operator_site: "ИНН 713001533404 ОГРНИП 313715407900150"
        },
        {
          region: "Тюменская область",
          operator: "ООО «Солоджи Эксперт»",
          operator_site: "https://sology.expert/"
        },
        {
          region: "ХМАО-Югра",
          operator: "ИП Толкачев Александр Владимирович",
          operator_site: "ИНН 860222049945 ОГРНИП 312860224200096"
        }
      ],
      showLoadMore: true,
      offset: 0,
      posts: null
    }
  },
  mounted() {
    ApiService.get('pages/about').then(res => {
      this.page = res.data.text
    })
    ApiService.get('posts', {
      params: {
        order_direction: 'DESC'
      }
    }).then(res => {
      this.posts = res.data.posts
    })
    ApiService.get('courses', {
      params: {
        program: 3
      }
    }).then(res => {
      this.courses = res.data.courses
    })
    this.load()
  },
  methods: {
    load(first = true) {
      ApiService.get('intramuralcontacts', {
        params: {
          limit: LIMIT,
          offset: this.offset
        }
      }).then(res => {
        if(res.status === 200) {
          this.contacts = first ? res.data.contacts : this.contacts.concat(res.data.contacts)
        } else {
          this.showLoadMore = false
        }
        this.$parent.init()
      })
    },
    loadMore() {
      this.offset += LIMIT
      this.load(false)
    }
  }
}
</script>

<style lang="scss">
.section__about-all h1 {
  margin-bottom: 2.4rem!important;
  font-weight: 700!important;
  font-size: 3.2rem!important;
}
.section__about-all blockquote {
  margin-bottom: 2rem;
  padding: 2.4rem 4.8rem;
  background: #fff;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.3;
  border-left: 0.8rem solid #ed1b2f;
}
@media (max-width: 768px) {
  .section__about-all h1 {
    font-size: 24px!important;
    line-height: 1!important;
    margin-bottom: 24px!important;
  }
}
</style>