<template>
  <div v-if="program && courses">
    <div class="row align-items-stretch mb-5">
      <div class="col section__education-description">
        <div class="title-with-icon mb-4">
          <div class="title-with-icon__icon title-with-icon__icon_programms-school"></div>
          <div class="title-with-icon__title">Обучение: корпоративное обучение</div>
        </div>
        <div class="section__education-description-text">
          <p>Корпоративное обучение по курсам Школы экспорта основам ВЭД может быть организовано для сотрудников предприятий, заинтересованных в ведении несырьевого экспорта. Обучение проводится в форме бизнес-тренингов и построено по принципу «60% теории - 40% практики»: участники обучения получают знания и на месте отрабатывают их на практике, решая задачи и кейсы.</p>
          <p>Обучение может быть организовано в офисе предприятия или в учебном классе Школы экспорта в Москве. По итогам обучения участники, успешно прошедшие итоговое тестирование, получают Удостоверения о повышении квалификации установленного образца. Задать вопросы о корпоративном обучении и оставить заявку на его проведение вы можете по форме ниже.</p>
        </div>
        <div><a href="#" @click.prevent="checkAuth()" data-toggle="modal" data-target="#add-question" class="link-target mt-3"><span>Оставить заявку</span></a></div>
      </div>
      <div v-if="newCourse" class="col-md-4 mb-3 mb-md-0 section__education-tag">
        <div class="container-shadow">
          <div class="section__education-tag-name">Новый курс</div>
          <div class="section__education-tag-title">
            <a :href="'/education/start-eksportnoy-deyatelnosti-10-shagov-k-uspehu/' + newCourse.alias">
            {{ newCourse.title }}
            </a>
          </div>
          <div class="section__education-tag-content" v-html="newCourse.short_desc"></div>
          <div><a :href="'/education/start-eksportnoy-deyatelnosti-10-shagov-k-uspehu/' + newCourse.alias" class="link-target"><span>подробнее</span></a></div>
        </div>
      </div>
    </div>

    <div class="row section__education-filters mb-3">
      <div class="col mb-1">
        <div class="d-inline-flex section__education-filters-sort">
          <div class="mr-3">Сортировать по:</div>
          <a href="javascript:void(0)" @click="orderByEvents('rank')" :class="{'active' : orderBy === 'rank'}" class="mr-2">Популярности</a>
          <a href="javascript:void(0)" @click="orderByEvents('date')" :class="{'active': orderBy === 'date'}">Дате</a>
        </div>
      </div>
      <div class="col-auto mb-1">
        <span class="section__education-filters-close" @click="clearFilters">Сбросить фильтр</span>
      </div>
    </div>
    <template v-if="courses">
      <div v-for="course in courses" :key="course.id" :class="{loading: loading}" class="program-card container-shadow">
        <div class="program-card__grid">
          <div class="program-card__grid-img">
            <img v-if="course.preview" :src="course.preview['500x']"/>
            <img v-else src="/pic/course-img.jpeg"/>
          </div>
          <div class="program-card__grid-data">
            <div class="program-card__grid-date">{{ course.start_date_text }}</div>
            <div class="program-card__grid-title">
              <a href="#">
                {{ course.title }}
              </a>
            </div>
            <div class="program-card__grid-content">
              {{ course.short_desc }}
            </div>
            <div class="program-card__grid-links d-flex align-items-center justify-content-between">
              <div class="mt-1"><a class="btn btn-outline-primary" :href="`/education/${program.alias}/${course.alias}`">Подробнее</a></div>
              <div class="mt-1"><a href="#" @click.prevent="checkAuth()" data-toggle="modal" data-target="#add-question" class="link-target ml-0"><span>подать заявку</span></a></div>
            </div>
          </div>
          <div class="program-card__grid-info">
            <div class="program-card__grid-info-item program-card__grid-info_rate">
              <div class="program-card__grid-info-label"><a :href="`/education/${program.alias}/${course.alias}#discuss`">{{ course.review_count }}</a></div>
              <div class="program-card__grid-info-content">
                <div class="rating">
                  <span class="filled"></span>
                  <span class="filled"></span>
                  <span class="filled"></span>
                  <span class="filled"></span>
                  <span class="semi-filled"></span>
                </div>
              </div>
            </div>
            <div class="program-card__grid-info-item">
              <div class="program-card__grid-info-label">Стоимость:</div>
              <div class="program-card__grid-info-content">по запросу</div>
            </div>
            <div class="program-card__grid-info-item">
              <template v-if="course.tutors.length">
                <div class="program-card__grid-info-label">Авторы:</div>
                <div class="program-card__grid-info-content">
                  <template v-if="!showMore[course.id]">
                      <span v-for="(tutor, index) in course.tutors.slice(0, 4)" :key="index">
                          {{ tutor.fullname }}<br/>
                      </span>
                  </template>
                  <template v-else>
                      <span v-for="(tutor, index) in course.tutors" :key="index">
                          {{ tutor.fullname }}<br/>
                      </span>
                  </template>
                  <div class="show-more" v-if="course.tutors.length > 4">
                      <span class="href" @click="showMoreTutor(course.id)">
                        {{ showMore[course.id] ? 'Скрыть' : 'Показать ещё' }}
                      </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import {TokenService} from "../../services/token.service";
const DEFAULT_PROGRAM = 2
export default {
  name: "Corporates",
  data() {
    return {
      courses: null,
      showMore: {},
      newCourse: null,
      direction: 'asc',
      orderBy: null,
      program: null,
      loading: false
    }
  },
  mounted() {
    this.getEvents()
    ApiService.get(`programs/${DEFAULT_PROGRAM}`).then(res => {
      this.program = res.data
    })
  },
  methods: {
    orderByEvents(orderBy = 'rank') {
      this.direction = this.direction === 'asc' ? 'desc' : 'asc'
      this.orderBy = orderBy
      this.getEvents()
    },
    clearFilters() {
      this.direction = null
      this.orderBy = null
      this.getEvents()
    },
    getEvents() {
      this.loading = true
      ApiService.get('courses', {
        params: {
          limit: 15,
          program: DEFAULT_PROGRAM,
          order_by: this.orderBy,
          order_direction: this.direction
        }
      }).then(res => {
        this.loading = false
        this.courses = res.data.courses
        this.newCourse = res.data.newCourse
      })
    },
    showMoreTutor(id) {
      this.showMore[id] = !this.showMore[id]
      this.getEvents()
    },
    checkAuth() {
      if(!TokenService.getUser()) {
        window.location.href = '/sign-in'
      }
    }
  }
}
</script>

<style scoped>

</style>