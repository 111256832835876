<template>
  <div class="seminar-item container-shadow" v-if="event">
    <div class="seminar-item__body">
      <div class="seminar-item__date">
        <div class="seminar-item__day">{{ event.date.start | formatDate('D') }}</div>
        <div class="seminar-item__month">{{ event.date.start | formatDate('M') }} {{ event.date.start | formatDate('Y') }}</div>
        <div class="seminar-item__time">{{ event.date.start | formatDate('HH:mm') }}</div>
      </div>
      <div class="seminar-item__image">
        <img :src="event.cover ? event.cover['500x'] : '/images/event.jpeg'" />
      </div>
      <div class="seminar-item__description">
        <div class="d-flex justify-content-between mb-3">
          <div class="seminar-item__type">{{ event.api_type }}</div>
          <div class="seminar-item__viewers">{{ event.count_participants }}</div>
        </div>
        <div class="seminar-item__title">
          <h2><a :href="`/events/${event.id}`">{{ event.title }}</a></h2>
        </div>
        <div class="seminar-item__coach" v-for="(tutor, index) in event.tutors" :key="index">
          <span class="d-block">Тренер</span>
          <div>{{ tutor.fullname }}</div>
        </div>
      </div>
    </div>
    <div class="seminar-item__footer d-flex justify-content-between">
      <div class="seminar-item__location" v-html="event.location"></div>
      <router-link :to="`/events/${event.id}`" :href="`/events/${event.id}`" class="link-target"><span>записаться на семинар</span></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeminarItem",
  props: ['event']
}
</script>

<style scoped>

</style>