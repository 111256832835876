<template>
  <section class="section section__coaches" id="section4">
    <div class="section__wrapper">
      <div class="section__coaches-wrapper">
        <div class="title-with-icon mb-2">
          <div class="title-with-icon__icon title-with-icon__icon_coach"></div>
          <div class="title-with-icon__title"><a href="/tutors">Тренеры и наставники</a></div>
        </div>
        <div class="owl-carousel section__coaches-carousel">
          <a :href="'/tutors?id=' + tutor.id" v-for="(tutor, index) in tutors" :key="index" class="item">
            <div class="section__coaches-item container-shadow">
              <div class="section__coaches-carousel-img avatar avatar_big" :style="{backgroundImage: tutor.user.avatar ? 'url(' + tutor.user.avatar['500x'] + ')': ''}"></div>
              <div class="section__coaches-carousel-title">{{ tutor.fullname }}</div>
              <div class="section__coaches-carousel-region"><span v-if="tutor.region">{{ tutor.region.name }}</span></div>
            </div>
          </a>
        </div>

        <div class="title-with-icon mt-1 mb-2">
          <div class="title-with-icon__icon title-with-icon__icon_partner"></div>
          <div class="title-with-icon__title"><a href="/partners">Партнеры</a></div>
        </div>
        <div class="owl-carousel section__coaches-partner-carousel">
          <div class="item" v-for="(partner, index) in partners" :key="index">
            <div class="section__coaches-item container-shadow partners">
              <div class="section__coaches-partner-carousel-img" :class="{reu: index === 2}">
                <img :src="partner.src"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Coachers",
  props: ['tutors'],
  data() {
    return {
      partners: [
        {
          src: '/pic/partners/sibur.jpg',
          href: 'https://www.sibur.ru/',
          alt: 'СИБУР'
        },
        {
          src: '/pic/partners/vavt.png',
          href: 'http://www.vavt.ru/',
          alt: 'ВАВТ'
        },
        {
          src: '/pic/partners/reu.png',
          href: 'https://new.rea.ru/',
          alt: 'РЭУ'
        },
        {
          src: '/pic/partners/skolkovo.jpg',
          href: 'https://sk.ru/',
          alt: 'Сколково'
        }
      ]
    }
  }
}
</script>