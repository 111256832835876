var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"navigation",attrs:{"id":"menu"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),(false)?_c('li',{attrs:{"data-menuanchor":"coaches"}},[_vm._m(4)]):_vm._e(),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"data-menuanchor":"front"}},[_c('a',{attrs:{"href":"#front"}},[_c('span',[_vm._v("01")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"data-menuanchor":"learn"}},[_c('a',{attrs:{"href":"#learn"}},[_c('span',[_vm._v("02")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"data-menuanchor":"school"}},[_c('a',{attrs:{"href":"#school"}},[_c('span',[_vm._v("03")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"data-menuanchor":"news"}},[_c('a',{attrs:{"href":"#news"}},[_c('span',[_vm._v("04")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#coaches"}},[_c('span',[_vm._v("05")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"data-menuanchor":"footer"}},[_c('a',{attrs:{"href":"#footer"}},[_c('span',[_vm._v("05")])])])
}]

export { render, staticRenderFns }