<template>
  <div class="author container-shadow">
    <div class="author__image">
      <div class="avatar avatar_big container-shadow" :style="{backgroundImage: 'url(' + (tutor.user.avatar ? tutor.user.avatar['500x'] : '/pic/user-pic.jpeg') + ')'}"></div>
    </div>
    <div class="author__content">
      <div class="author__name">{{ tutor.fullname }}</div>
      <div class="author__data">
        <div class="author__data-label">Регион</div>
        <div class="author__data-content">{{ tutor.region.name }}</div>
      </div>
      <div class="author__data" v-if="tutor.experience">
        <div class="author__data-label">Опыт и стаж работы:</div>
        <div class="author__data-content" :class="{'less-text': !tutor.showMore}" v-html="tutor.experience"></div>
        <div @click="showMore(tutor)" class="show-more">{{ !tutor.showMore ? 'Подробнее' : 'Скрыть' }}</div>
      </div>
      <div class="author__data">
        <div class="author__data-label">Образование:</div>
        <div class="author__data-content" v-html="tutor.education"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Author",
  props: ['tutor'],
  methods: {
    showMore(tutor) {
      this.$set(tutor, 'showMore', !tutor.showMore)
    }
  }
}
</script>

<style scoped>
.less-text {
  max-height: 60px;
  overflow: hidden;
}
.show-more {
  margin-top: 10px;
}
</style>