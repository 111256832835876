<template>
  <section class="section section__news" id="section3">
    <div class="section__wrapper">
      <div class="section__news-wrapper">
        <div class="title-with-icon mb-2">
          <div class="title-with-icon__icon title-with-icon__icon_news"></div>
          <div class="title-with-icon__title"><a href="/news">Новости</a></div>
        </div>
        <div class="section__news-carousel owl-carousel">
          <div v-for="(post, index) in posts" :key="index" class="item">
            <div class="section__news-item container-shadow">
              <div class="section__news-image" :style="{
                backgroundImage: post.cover ? 'url(' + post.cover['500x'] + ')' : '',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }"></div>
              <div class="section__news-content">
                <div class="section__news-title"><a :href="'/news/' + post.id">{{ post.title }}</a></div>
                <div class="section__news-text">
                  {{ post.short_text }}
                </div>
              </div>
              <div class="section__news-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="section__news-view">{{ post.views_count }}</div>
                  <a :href="'/news/' + post.id" class="link-target"><span>Подробнее</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: "News",
  props: ['posts']
}
</script>

<style scoped>
.section__news-text {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>